<!--
 * @Descripttion:
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-07 16:45:32
-->
<template>
	<div v-if="hasData">
		<div v-show="searchV =='' || hasSearchData">
			<ul>
				<li v-for="(item,index) in list" :key="index" :class="{test : index ==0}">
					<div class="flex-item" @click="item.click = !item.click">
						<h3 class="mar-l2">{{ item.name }}</h3>
						<van-icon name="play" :class="{arrow:true,'arrow-up':item.click}" />
					</div>
					<ul v-show="item.click">
						<li v-for="(child,i) in item.children" :key="child.id" class="flex-item son" @click="selectedSon(item,child)">
							<h3 class="mar-l2">{{showMajorAlias(child) }}</h3>
						</li>
					</ul>
				</li>
			</ul>
		</div>
		<div class="h2 bg"></div>
		<div class="fixed-bottom pad25 whiteBg">
			<button class="btn w-100" @click.prevent="doClick">{{ btnText }}</button>
		</div>

		<!-- 评审专业： 搜索无数据 -->
		<div v-show="searchV !='' && !hasSearchData" class="pad" style="background-color:#f5f5f5">
			<div class="whiteBg pad b1 flex-col">

				<img src="/static/img/null/search.png" class="div7">
				<h3 class="f45 mar-t">无搜索记录</h3>
				<p class="f35 mar-t2 gray">没有搜索到相关内容，请重新搜索。</p>
			</div>
		</div>
	</div>
	<!-- 无评审专业数据 -->
	<div v-else style="background-color:#f5f5f5; padding:0.5rem">
		<div class="b1 whiteBg pad-b pad-t">
			<div class="flex-col ">
				<img src="/static/img/null/profession.png" class="div7">
				<h4 class="f45 center">无评审专业</h4>
				<p class="gray mar-t2 f35">请点下方按钮，设置评审专业</p>
				<button class="btn mar-t" @click="$router.push('/profession/edit')">设置评审专业</button>
			</div>

		</div>
	</div>
</template>

<script>
	import {
		Icon,
		Dialog
	} from 'vant'
	export default {
		data() {
			return {
				list: [],
				gongchengList: [],
				huowuList: [],
				fuwuList: [],
				queryParam: {
					orgId: "d6f2e5258fe94834b1e8e7661a28fdc7",
					majorName: ''
				},
				pageParams: {
					pageNo: 1,
					pageSize: 20
				},
				btnText: '选择评审专业',
				// 是否有专业数据 默认为 有
				hasData: true,
				// 是否有搜索数据
				hasSearchData: true,
				// 父级页面的搜索内容
				searchV: '',
				orgExternal: {
					id: '',
					serviceStatus: '',
					surplusDays: 0,
				},
				workweixin: false,				//企业微信
				openUserid:	"",
			}
		},

		props: {
			activeIndex: {
				type: [String, Number],
				required: true
			}
		},

		watch: {
			activeIndex(newV) {
				if (newV == 0) this.list = this.gongchengList;
				else if (newV == 1) this.list = this.huowuList;
				else if (newV == 2) this.list = this.fuwuList;
			}
		},
		created() {
			//企微微信标志
			this.workweixin = this.$ls.get("expert_workweixin_flag")&&"1"==this.$ls.get("expert_workweixin_flag")
			if(this.workweixin){
				this.openUserid = this.$ls.get("expert_workweixin_user").open_userid
			}
			//
			if (this.$ls.get("edCurrentOrgExternal")) {
				this.orgExternal = this.$ls.get("edCurrentOrgExternal");
			}
			//获取公司信息
			if (this.$ls.get("edCurrentOrg")) {
				this.queryParam.orgId = this.$ls.get("edCurrentOrg").id;
			}
			this.getTreeData();
			this.$emit('onShowSearch', true)
			this.$emit('onRouteIndex', 0)
		},
		components: {
			[Icon.name]: Icon,
			[Dialog.name]: Dialog,
		},

		methods: {

			/**
			 * @Description: 一级专业的全选与取消
			 * @Author: Niklaus
			 * @Date: 2021-01-06 15:18:40
			 * @param {*} item
			 */
			selectedParent(item) {
				item.checked = !item.checked
				for (let i = 0; i < item.children.length; i++) {
					const ele = item.children[i].checked = item.checked;
				}
			},

			/**
			 * @Description: 二级专业的 选中与取消
			 * @Author: Niklaus
			 * @Date: 2021-01-06 15:08:51
			 * @param {*} item ： 一级专业
			 * @param {*} child： 二级专业
			 */
			selectedSon(item, child) {
				child.checked = !child.checked
				let hasChecked = false
				for (let i = 0; i < item.children.length; i++) {
					const ele = item.children[i];
					if (ele.checked) {
						hasChecked = true
						break
					}
				}
				item.checked = hasChecked
			},
			//获取专业树结构
			getTreeData() {
				var params = Object.assign({}, this.queryParam)
				params.pageNo = this.pageParams.pageNo;
				params.pageSize = this.pageParams.pageSize;
				params.mapDelFlag = "0"
				params.openid = this.workweixin?this.openUserid:this.$ls.get("openid")


        /** 添加测试数据 */
        // params.openid = 'woRz2sCAAAsDaCf2El5R-ir9TBNAJl8g'
        // params.orgId = 'wwcb3084429faea701'
				let accesstoken = this.$ls.get("token")
				this.$ajax({
					methods: 'get',
					url: '/gateway/ed/wx/wxEdReviewMajorTwoThreeClassController/list/',
					headers: {
						'X-Access-Token': this.$ls.get("token")
					},
					params: params,
				}).then(res => {
					if (res.data.success) {
						this.list = res.data.result.gongchengList;
						this.gongchengList = res.data.result.gongchengList;
						this.huowuList = res.data.result.huowuList;
						this.fuwuList = res.data.result.fuwuList;
						if (this.gongchengList.length > 0 || this.huowuList.length > 0 || this.fuwuList.length > 0)
							this.btnText = '修改评审专业'
						else
							this.hasData = false
					} else {
						this.$toast.fail("失败")
						console.log(res.data.message)
					}
				});
			},
			//开始下一个方法
			searchByMajorName() {
				console.log(12131);
			},

			/**
			 * @Description: 调用搜索：若搜索结果无数据，通知父级 隐藏大类
			 * @Author: Niklaus
			 * @Date: 2021-01-28 16:37:04
			 * @param {*} searchV： 父级页面的 搜索内容
			 */
			doSearch(searchV) {
				this.searchV = searchV
				this.queryParam.majorName = this.searchV;
				this.getTreeData();
				let i = 0;
				if (this.gongchengList.length > 0) {
					i++;
				}
				if (this.huowuList.length > 0) {
					i++;
				}
				if (this.fuwuList.length > 0) {
					i++;
				}
				if (i == 0) {
					this.hasSearchData = false
				} else {
					this.hasSearchData = true
				}

				if (!this.hasSearchData) {
					this.$emit('onTitle', false)
				} else {
					this.$emit('onTitle', true)
				}
			},
			//优先显示备注
			showMajorAlias(item) {
				if (item.majorAlias != null && item.majorAlias != '') {
					return item.majorAlias
				} else {
					return item.majorName
				}
			},
			//清除
			doClear() {
				this.searchV = '';
				this.queryParam.majorName = '';
			},
			//下一个方法
			doClick() {
				if (this.orgExternal.serviceStatus == null || this.orgExternal.serviceStatus == '00' || this.orgExternal.serviceStatus ==
					'02') {
					Dialog.confirm({
						title: '提示',
						message: '本功能需要开通专家记服务才能使用',
						confirmButtonText: '去开通服务',
						cancelButtonText: '取消',
					}).then(() => { //确认
						this.$router.push({
							path: '/profession/edit'
						});
					}).catch(() => {
						console.log('取消')
					})
					return;
				}
				this.$router.push({
					path: '/profession/edit'
				});
			}
		}
	}
</script>

<style scoped>
	.flex-item {
		margin-top: 0.2rem;
		position: relative;
		padding: 0.1rem 0.5rem
	}

	.test {
		padding-top: 0.1rem;
	}
</style>
